<template>
<path d="M388 984 c-251 -60 -421 -311 -380 -556 37 -219 201 -383 420 -420
318 -54 618 246 564 565 -49 289 -327 479 -604 411z m-108 -330 l0 -85 26 20
c38 30 103 28 137 -3 31 -29 39 -77 35 -201 l-3 -90 -40 0 -40 0 -3 115 c-2
94 -6 117 -19 128 -25 18 -41 15 -68 -13 -23 -23 -25 -31 -25 -130 l0 -105
-40 0 -40 0 0 225 0 225 40 0 40 0 0 -86z m318 3 l3 -77 29 15 c48 25 97 19
131 -14 l29 -29 0 -131 0 -131 -40 0 -40 0 0 114 c0 91 -3 118 -16 130 -19 20
-44 20 -70 2 -16 -12 -20 -30 -24 -128 l-5 -113 -40 0 -40 0 -3 210 c-1 115 0
215 3 223 3 9 18 12 42 10 l38 -3 3 -78z" transform="translate(0,24) scale(0.024,-0.024)"/>
</template>

<script>
    export default {
        name: 'headhunter',
    }
</script>
